body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, #381990 0%, #2DA3E0 100%);
  width: auto;
  margin-left: 30px;
  margin-top: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.synodos-logo-container {
  width: 96.909px;
  height: 141px;
  flex-shrink: 0;
  vertical-align: middle;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 300px;
}

.demo-border {
  width: 105.408px;
  height: 51.779px;
  color: #F15FBD;
  border: #F15FBD;
  border-width: 2px;
}

.demo-label {
  display: flex;
  width: 72.121px;
  height: 22.191px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #F15FBD;
}


.box-colleagues {
  width: 450px;
  height: 450px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #000;
  position: relative;
  padding: 15px;
}

/* Style for each pair of side-by-side divs */
.side-by-side {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* Center horizontally */
  /* Add some spacing between pairs */
  padding: 20px;
}



.navi,
.infoi {
  position: absolute;
}

.infoi {
  z-index: 100;
}

.object {
  flex-basis: 48%;
  /* Adjust the width as needed */
  padding: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.side-by-side-chat-history {
  justify-content: center;
  /* Center horizontally */
  margin-bottom: 10px;
  /* Add some spacing between pairs */
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  height: 900px;
  width: 400px;
  position: flex;

}



.appcontainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.side-by-side {
  justify-content: space-between;
}

.object {
  flex-basis: 48%;
  /* Adjust the width as needed */
  padding: 20px;
  box-sizing: border-box;
  border-color: transparent;
}

.container2 {
  width: 1080px;
  flex-shrink: 0;
  position: flex;
  vertical-align: top;

}

.webcamcontainer {
  width: 540px;
  height: 450px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #000;
  position: relative;
  padding: 15px;
}

.didsessioncontainer {
  width: 450px;
  height: 450px;
  flex-shrink: 0;
  border-radius: 16px;
  background: red;
  position: relative;
  padding: 15px;
}

.didsessioncontainer2 {
  width: 450px;
  height: 450px;
  flex-shrink: 0;
  border-radius: 16px;
  background: palegreen;
  position: relative;
  padding: 15px;
}

.objectdidsession {
  /* flex-basis: 48%; */
  /* Adjust the width as needed */
  box-sizing: border-box;
  border-color: transparent;
}

.humanlivedata {
  width: 400px;
  height: 600px;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.25);
}

.humanlivedatabox {
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.25);
  width: 500px;
  height: 350px;
  flex-shrink: 0;
  text-align: left;
  padding: 15px;
  position: relative;
}


.livedataheadertext {
  color: #FFF;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  position: absolute;
  align-items: center;
  text-align: center;
}

.telematry-box {
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.25);
  width: 500px;
  height: 350px;
  flex-shrink: 0;
  padding: 15px;
}


#wrapper {
  display: grid;
  /* 1 */
  grid-template-columns: repeat(3, 520px);
  /* 2 */
  grid-auto-rows: 530px;
  /* 3 */
  grid-auto-flow: column;
  /* 3 */
  grid-gap: 20px;
  /* 4 */
}

.tall {
  grid-row: 1 / 3;
  /* 5 */
  grid-column: 3 / 3;
  /* 5 */
}

.wide {
  grid-row: 2 / 4;
  /* 6 */
  grid-column: 3 / 5;
  /* 6 */
}

.block {
  width: 500px;
  height: 430px;
  vertical-align: top;
  margin: 10px;
  align-items: center;
  text-align: center;
}

#headerwrapper {
  display: grid;
  /* 1 */
  grid-auto-flow: column;
  /* 4 */
  vertical-align: middle;
  /* 4 */
  grid-template-columns: fit-content(400px) fit-content(400px) fit-content(300px) fit-content(300px);
  grid-gap: 5px;
  box-sizing: border-box;
  height: 160px;
}

.headerblock {
  background-color: transparent;
  vertical-align: middle;
}

.digital-colleague-header {
  background-color: transparent;
  vertical-align: middle;
  display: flex;
  width: 495.774px;
  height: 18.026px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  padding-top: 80px;
}

.history-text-history {
  width: 450px;
  height: 1010px;
  border-color: transparent;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
  padding-top: 40px;
  text-align: center;
}

.history-input-box {
  width: 400px;
  height: 1010px;
  border-color: black;
  border-radius: 16px;
  background: #FFF;
  padding-top: 40px;
  text-align: center;
}



.voiceDetectionBox {
  padding: 0px;
  margin: 0px;
  flex-shrink: 0;
}

.userDetectionBox {
  padding: 15px;
  margin: 0px;
  flex-shrink: 0;
}

#user-detection-wrapper {
  width: 450px;
  position: relative;
  padding: 15px;
}

#naviUD,
#infoiUD {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#infoiUD {}

.userDetection-container {
  width: 500px;
  height: 360px;
  border-radius: 16px;
  background: #000;
  overflow: hidden;
}

.didvideo-container {
  width: 500px;
  height: 450px;
  border-radius: 16px;
  background: #000;
  padding: 15px;
  overflow: hidden;
}



.userDetection-Live-Button {
  border-radius: 26px;
  background: linear-gradient(135deg, #B94EFB 0%, #F256BB 50%, #E96363 100%);
  width: 100px;
  height: 28px;
  color: #FFF;
  position: absolute;
  text-align: center;
  vertical-align: middle;
}

.webcam-header-text {
  color: #FFF;
  width: 100px;
  height: 28px;
  vertical-align: middle;
  text-align: right;
  padding-right: 20px;
  z-index: 10000;
}


#webcam-live-wrapper {
  display: grid;
  /* 1 */
  grid-template-columns: repeat(2, 110px);
  /* 2 */
  grid-auto-rows: 150px;
  /* 3 */
  grid-auto-flow: column;
  height: 28px;
}

.webcam-live-block {
  text-align: left;
  background-color: transparent;
  height: 28px;
  vertical-align: middle;
  z-index: 10000;
  position: relative;
}

.emotionDetectionBox {
  z-index: 1;
  position: absolute;
  padding-top: 200px;
}

.transcript-input-box {
  width: 450px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.25);
  text-align: left;
  vertical-align: top;
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
}


.button {
  display: flex;
  width: 110px;
  height: 23px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.dButton {
  display: flex;
  width: 110px;
  height: 23px;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  flex-shrink: 0;
  cursor: pointer;
  text-align: center;
  margin: 20px;
}








.message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  max-height: 400px;
  /* Add a maximum height to enable scrolling */
  overflow-y: auto;
  /* Enable vertical scrolling when content exceeds max-height */
}

.message {
  border-radius: 10px;
  padding: 10px 15px;
  max-width: 70%;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.4;
}

.user-message {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.other-message {
  background-color: #f0f0f0;
  color: #333;
  align-self: flex-start;
}

.session-connection-info {
  color: #FFF;
}